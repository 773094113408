import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useDebounce from '../Hooks/useDebounce';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FilledInput from '@mui/material/FilledInput';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { onData, offData } from '../Hooks/Socket';
import {
  faSearch,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';

const OfflineStyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ff0000',
    color: '#ff0000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const OnlineStyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default function NewChannels() {
  //Would be nice to split the parts of this component into their own components
  const [users, setUsers] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [departments, setDepartments] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState(localStorage.getItem('filter') || 'all');
  const [search, setSearch] = useState(localStorage.getItem('search') || '');
  const [selectedDepartment, setSelectedDepartment] = useState(
    localStorage.getItem('selectedDepartment') || '1'
  );
  const [isLoading, setIsLoading] = useState(true);

  const debouncedSearch = useDebounce(search, 350);

  //Abort Controller
  const requestAbortController = useRef(null);

  // Pagination Variables
  // 20 users per page
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const history = useHistory();

  const nextPage = () => {
    setPage((currPage) => {
      if (currPage < maxPage) {
        return currPage + 1;
      } else {
        return currPage;
      }
    });
  };

  const previousPage = () => {
    setPage((currPage) => {
      if (currPage > 1) {
        return currPage - 1;
      } else {
        return currPage;
      }
    });
  };

  const updateUserLive = ({ userId }) => {
    setUsers((users) =>
      users.map((user) => {
        if (user.userId === userId) {
          user.userStatus = 1;
          return user;
        }
        return user;
      })
    );
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        boxShadow: (theme) => theme.shadows[1],
        height: '3.25rem',
        width: '3.25rem',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  const handleViewHistory = (userId) => {
    history.push(`/new-user/${userId}`);
  };

  const handleWatchLive = (userId) => {
    const date = dayjs().format('MM-DD-YYYY');

    history.push(`/user/${userId}/${date}/live`);
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      id={nodes.id}
      nodeId={nodes.id ? nodes.id : '1'}
      label={nodes.name}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  useEffect(() => {
    //Check abort controller
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    //Abort Controller
    const controller = new AbortController();

    //Fetch users
    const params = {
      method: 'GET',
      credentials: 'include',
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('genUserId')}`,
      },
    };

    Promise.all([
      fetch(`${window.apiSite}/v1/getUsers`, params)
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);
          setUsers(data);
          setFilteredUsers(data);

          // Set max page
          setMaxPage(Math.ceil(data.length / 20));
        })
        .catch((err) => {
          console.error(err);
        }),
      fetch(`${window.apiSite}/v1/getDepartments`, params)
        .then((res) => res.json())
        .then((data) => {
          setDepartments(data[0]);
        })
        .catch((err) => {
          console.error(err);
        }),
      fetch(`${window.apiSite}/v1/getUserDepartments`, params)
        .then((res) => res.json())
        .then((data) => {
          let currExpanded = JSON.parse(localStorage.getItem('expanded')) || [
            ...data.map((department) => department.id.toString()),
          ];

          //If the current selected department is empty, set it to the last department of the users departments
          let currSelectedDepartment =
            localStorage.getItem('selectedDepartment') ||
            data[data.length - 1].id.toString();

          setExpanded(currExpanded);
          setSelectedDepartment(currSelectedDepartment);
        })
        .catch((err) => {
          console.error(err);
        }),
    ]);

    onData('addFilePart', updateUserLive);

    return () => {
      controller.abort();
      offData('addFilePart', updateUserLive);
    };
  }, []);

  //Run filter function when users array changes
  useEffect(() => {
    let filteredUsers = users;

    if (expanded.length > 0) {
      filteredUsers = filteredUsers.filter((user) => {
        if (expanded.length === 1 && expanded[0] === '1') {
          return user;
        }

        if (user?.departmentIds !== null) {
          if (user.departmentIds.includes(selectedDepartment)) {
            return user;
          }
        }
      });
    } else {
      filteredUsers = users;
    }

    //Filter by online/offline/active/inactive
    switch (filter) {
      case 'all':
        break;
      case 'online':
        filteredUsers = filteredUsers.filter((user) => user.userStatus === 1);
        break;
      case 'offline':
        filteredUsers = filteredUsers.filter((user) => user.userStatus === 0);
        break;
      case 'subscribed':
        filteredUsers = filteredUsers.filter((user) => user.subscribed);
        break;
      default:
        break;
    }

    filteredUsers = filteredUsers.filter((user) => {
      if (user.username.toLowerCase().includes(debouncedSearch.toLowerCase())) {
        return user;
      }
      if (user.employeeId) {
        if (
          user.employeeId.toLowerCase().includes(debouncedSearch.toLowerCase())
        ) {
          return user;
        }
      }
    });

    setFilteredUsers(filteredUsers);
  }, [users, filter, debouncedSearch, expanded, selectedDepartment]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} paddingTop="2rem">
        <Grid item xs={2} sm={2}>
          <TreeView
            aria-label="departments"
            defaultCollapseIcon={<FontAwesomeIcon icon={faChevronUp} />}
            defaultExpandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            multiSelect={false}
            expanded={expanded}
            selected={selectedDepartment}
            onNodeToggle={(e, value) => {
              setExpanded(value);
              localStorage.setItem('expanded', JSON.stringify(value));
            }}
            onNodeSelect={(e, value) => {
              setSelectedDepartment(value);
              localStorage.setItem('selectedDepartment', value);
            }}
            sx={{
              height: '100vh',
              flexGrow: 1,
              maxWidth: 400,
              overflowY: 'auto',
            }}
          >
            {renderTree(departments)}
          </TreeView>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={8}>
              <TextField
                fullWidth
                id="search"
                variant="filled"
                label="Search Users..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  localStorage.setItem('search', e.target.value);
                }}
                InputProps={{
                  sx: { height: '3.5rem' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Select
                fullWidth
                id="filter"
                label="Filter Users..."
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  localStorage.setItem('filter', e.target.value);
                }}
                input={<FilledInput />}
                sx={{ height: '3.5rem' }}
              >
                <MenuItem value={'all'}>All Users</MenuItem>
                <MenuItem value={'online'}>Online</MenuItem>
                <MenuItem value={'offline'}>Offline</MenuItem>
                <MenuItem disabled value={'subscribed'}>
                  Subscribed
                </MenuItem>
              </Select>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              sx={{ minHeight: '65vh' }}
              spacing={2}
              justifyContent="space-evenly"
              alignContent="space-evenly"
            >
              {isLoading && <CircularProgress />}
              {filteredUsers.slice((page - 1) * 20, page * 20).map((user) =>
                !user.userStatus ? (
                  <Grid item xs={6} sm={4} key={user.userId}>
                    <Card
                      raised
                      sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiCardHeader-subheader': {
                          fontStyle: 'italic',
                        },
                        '& .MuiCardHeader-action': {
                          alignSelf: 'center',
                        },
                      }}
                    >
                      <CardHeader
                        avatar={
                          <OfflineStyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar {...stringAvatar(user.username)} />
                          </OfflineStyledBadge>
                        }
                        title={user.username}
                        subheader={
                          user.idlePercent ? `${user.idlePercent} Active` : ''
                        }
                        action={
                          <Grid container spacing={1}>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleViewHistory(user.userId)}
                              >
                                View History
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled
                                variant="contained"
                                color="primary"
                                onClick={() => handleWatchLive(user.userId)}
                              >
                                Watch Live
                              </Button>
                            </Grid>
                          </Grid>
                        }
                      />
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={6} sm={4} key={user.userId}>
                    <Card
                      raised
                      sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiCardHeader-subheader': {
                          fontStyle: 'italic',
                        },
                        '& .MuiCardHeader-action': {
                          alignSelf: 'center',
                        },
                      }}
                    >
                      <CardHeader
                        avatar={
                          <OnlineStyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar {...stringAvatar(user.username)} />
                          </OnlineStyledBadge>
                        }
                        title={user.username}
                        subheader={
                          user.idlePercent ? `${user.idlePercent} Active` : ''
                        }
                        action={
                          <Grid container spacing={1}>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleViewHistory(user.userId)}
                              >
                                View History
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleWatchLive(user.userId)}
                              >
                                Watch Live
                              </Button>
                            </Grid>
                          </Grid>
                        }
                      />
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          {
            /* Pagination */
            filteredUsers.length > 20 && (
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={page === 1}
                      onClick={() => previousPage()}
                      color="secondary"
                    >
                      Previous Page
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={page === maxPage}
                      onClick={() => nextPage()}
                      color="primary"
                    >
                      Next Page
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
}
