import React, { useEffect, useState, useRef } from 'react';
import Loader from '../Fragments/Loader';
import StreamControls from '../Components/StreamControls';
import MultipleTrigger from '../Components/MultipleTrigger';
import Player from '../Components/Player';
import Viewer from '../Components/Viewer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// import {
//   FontAwesomeIcon
// } from '@fortawesome/react-fontawesome'
// import {
//   faExpandArrowsAlt, faCompressArrowsAlt
// } from '@fortawesome/free-solid-svg-icons'
import {
  joinRoom,
  leaveRoom,
  sendData,
  onData,
  offData,
} from '../Hooks/Socket';

const styles = {
  spinner: {
    height: '1.2rem',
    width: '1.2rem',
    marginLeft: '15px',
  },
  floatRightFlex: {
    marginLeft: 'auto',
  },
  alignMiddle: {
    alignItems: 'center',
  },
};

function User(props) {
  const [comments, setComments] = useState(null);
  const [stateTable, setStateTable] = useState(null);
  const [channelName, setChannelName] = useState(null);
  const [isUnavailable, setIsUnavailable] = useState(true);
  const [activePercent, setactivePercent] = useState('');
  const [errorMessage, setErrorMessage] = useState(<Loader />);
  const [isrc, setiSrc] = useState(null);
  const [width, setWidth] = useState(0);

  const playerIframe = useRef();
  // const [arrows, setArrow] = useState(0)

  const makeDate = (d) => {
    return (
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + d.getDate()).slice(-2) +
      '-' +
      d.getFullYear()
    );
  };

  const playOnLoad = (attempts, live, position, time) => {
    setTimeout(function() {
      if (Viewer.isReady && Viewer.canPlayNow) {
        if (live) {
          Viewer.liveFrame();
        } else {
          Viewer.start(position, time);
        }
      } else {
        attempts++;

        if (attempts <= 55) {
          playOnLoad(attempts, live, position, time);
        }
      }
    }, 100);
  };

  /**
   * Handles response to request
   * to get stream name and data.
   * The viewer's verification is done
   * on getting the stream name.
   * @param {JSON} data response data
   * @param {Number} data.verified if the viewer was verified to watch the user
   * @param {String} data.firstName the users first name
   * @param {String} data.lastName the users last name
   */
  const onGetName = (data) => {
    if (data.verified) {
      setIsUnavailable(false);
      setChannelName(`${data.firstName} ${data.lastName}`);
    } else {
      setIsUnavailable(true);
      setErrorMessage(
        <Alert variant="danger">
          <Alert.Heading>User Not Available!</Alert.Heading>
          <p>
            Oh no! It looks like the user you requested is unavailable or cannot
            be viewed at this time. If you believe this is an error, please
            report this information to your Systems Administrator.
          </p>
        </Alert>
      );
    }
  };

  /**
   * Handles response to getting
   * all comments for a given stream.
   * @param {Array} comments the comments on the stream
   */
  const onGetComments = (comments) => {
    let component = [];
    for (let comment of comments) {
      let commentStartTime = comment.timeInFrame ? comment.timeInFrame : 0;

      component.push(
        <tr key={comment.id}>
          <td>
            <button
              onClick={() => {
                playerIframe.current.contentWindow.postMessage(
                  {
                    event: 'play',
                    frame: comment.frame,
                    time: commentStartTime,
                  },
                  window.playerSite
                );
              }}
            >
              Play
            </button>
          </td>
          <td>{comment.author}</td>
          <td>{comment.comment}</td>
        </tr>
      );
    }

    setComments(<>{component}</>);
  };

  /**
   * Handles response to getting
   * the user state table of the stream.
   * @param {Array} table the items of the table
   */
  const onGetUserStateTable = (table) => {
    let component = [];
    table.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime());
    for (let item of table) {
      let testDate = item.startDateTime.replace('Z', '-07:00');
      let newDate = new Date(testDate).getTime();
      item.startDateTime = newDate;

      component.push(
        <tr key={item.startFrame}>
          <td>{new Date(item.startDateTime).toTimeString()}</td>
          <td>{new Date(item.duration * 1000).toISOString().substr(11, 8)}</td>
          <td>
            {item.startFrame == -1 ? (
              'No Valid Clip'
            ) : (
              <button
                onClick={() => {
                  playerIframe.current.contentWindow.postMessage(
                    {
                      event: 'play',
                      frame: item.startFrame,
                      time: item.timeOffset,
                    },
                    window.playerSite
                  );
                }}
              >
                Play Start Idle
              </button>
            )}
          </td>
          <td>
            {item.endFrame == -1 ? (
              'No Valid Clip'
            ) : (
              <button
                onClick={() => {
                  playerIframe.current.contentWindow.postMessage(
                    {
                      event: 'play',
                      frame: item.endFrame,
                      time: item.endOffset,
                    },
                    window.playerSite
                  );
                }}
              >
                Play end Idle
              </button>
            )}
          </td>
        </tr>
      );
    }

    setStateTable(<>{component}</>);
  };

  // const expandView = () => {
  //   let el = document.getElementById('playerContainerMain')
  //   if (el.classList.contains('expanded-view')) {
  //     el.classList.remove('expanded-view')
  //     setArrow(faExpandArrowsAlt)
  //   } else {
  //     el.classList.add('expanded-view')
  //     setArrow(faCompressArrowsAlt)
  //   }
  // }

  const handleMessage = (msg) => {
    if (msg.data.event === 'comment') {
      sendData('setComment', msg.data);
    }
  };

  useEffect(() => {
    setWidth(window.screen.availWidth);
    //TODO: Remove hardcoding of player url
    setiSrc(
      `${window.playerSite}/${props.match.params.id}/${props.match.params.datestamp}/0`
    );
    MultipleTrigger.tabsRole = 'Tab'; // Required field to use our custom component

    let pageRoute = props.match.url.match('/(.*?)/');
    let genUserId = localStorage.getItem('genUserId');
    let commentId = props.match.params.commentId;
    let userId = props.match.params.id;

    if (pageRoute[1] == 'user') {
      Viewer.page = 'user';
      Viewer.isReady = false;
      Viewer.urlUser = parseInt(userId);

      if (commentId) {
        let data = {
          genUserId: genUserId,
          commentId: commentId,
          userId: userId,
          channel: `individual-${userId}`,
        };
        sendData('mentionRead', data);
      }

      if (props.match.params?.datestamp) {
        Viewer.setCustomDay(props.match.params.datestamp);
      }
    }

    // setArrow(faCompressArrowsAlt)
    joinRoom(`group-${Viewer.urlUser}`);

    let getNameData = {
      genUserId: genUserId,
      userId: userId,
      channel: `individual-${userId}`,
    };
    sendData('getName', getNameData);

    let getCommentsData = {
      channel: `group-${userId}`,
      objectId: userId,
      genUserId: genUserId,
      isClip: false,
      streamDate: Viewer.getCustomDay(),
    };
    sendData('getComments', getCommentsData);

    let getUserStateTableData = {
      channel: `group-${userId}`,
      userId: userId,
      genUserId: genUserId,
      date: Viewer.getCustomDay(),
    };
    sendData('getUserStateTable', getUserStateTableData);

    onData('getName', onGetName);
    onData('getComments', onGetComments);
    onData('getUserStateTable', onGetUserStateTable);

    document.addEventListener(
      'pointerdown',
      () => {
        Viewer.canplay();
      },
      false
    );

    if (props.match.params?.position) {
      playOnLoad(
        0,
        false,
        parseInt(props.match.params.position),
        parseInt(props.match.params.time)
      );
    } else {
      playOnLoad(0, true);
    }

    //Iframe Player message listener
    window.addEventListener('message', handleMessage);

    return () => {
      leaveRoom(`group-${Viewer.urlUser}`);
      clearTimeout(Viewer.createFrameTimer);
      clearTimeout(Viewer.frameIndexTimer);
      offData('getName');
      offData('getComments');
      offData('getUserStateTable');
      window.removeEventListener('message', handleMessage);
    };
  }, [props.match.params.id, props.match.params.commentid]);

  return isUnavailable ? (
    <React.Fragment>
      <Container>{errorMessage}</Container>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Container id="playerContainerMain" className="expanded-view">
        <Row style={{ marginTop: '15px' }}>
          <Card style={{ width: '100%' }}>
            <Card.Header>
              <Row style={styles.alignMiddle}>
                <div
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span className="ml-2">
                    {channelName} - {Viewer.getCustomDay()} - {activePercent}{' '}
                  </span>
                </div>
                {/* <Spinner 
					id="playerSpinner" 
					style={styles.spinner} 
					animation="grow" 
					role="status" 
					variant="danger"
					>
					<span className="sr-only">Loading...</span>
					</Spinner> */}
                {/* <Button 
					variant="light" 
					style={styles.floatRightFlex}
					onClick={expandView}
					>
					<FontAwesomeIcon icon={arrows} />
					
					</Button> */}
              </Row>
            </Card.Header>
            <Card.Body style={{ minHeight: '800px' }}>
              <iframe
                height="850"
                width="100%"
                id="player"
                src={isrc}
                ref={playerIframe}
              ></iframe>
            </Card.Body>
          </Card>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Tabs>
            <TabList>
              <Tab>Comments</Tab>
              <Tab>State</Tab>
            </TabList>
            <TabPanel>
              <MultipleTrigger
                data={Viewer.userList}
                playerIframe={playerIframe}
              />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}>Jump To</th>
                    <th style={{ width: '10%' }}>Author</th>
                    <th style={{ width: '60%' }}>Comment</th>
                    {/* <th style={{width: '10%'}}>Action</th> */}
                  </tr>
                </thead>
                <tbody>{comments}</tbody>
              </table>
            </TabPanel>
            <TabPanel>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}>Start Time</th>
                    <th style={{ width: '10%' }}>Duration</th>
                    <th style={{ width: '10%' }}>Start frame</th>
                    <th style={{ width: '60%' }}>End Frame</th>
                    {/* <th style={{width: '10%'}}>Action</th> */}
                  </tr>
                </thead>
                <tbody>{stateTable}</tbody>
              </table>
            </TabPanel>
          </Tabs>
        </Row>
      </Container>
    </React.Fragment>
  );
}

//Exports
export default User;
