//Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faRedo,
  faUserPlus,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { sendData, onData, offData } from '../../Hooks/Socket';
import React, { useEffect, useCallback } from 'react';
import useState from 'react-usestateref';
import Loader from '../Loader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InputGroup from 'react-bootstrap/InputGroup';
import Tooltip from 'react-bootstrap/Tooltip';
import PasswordChecklist from 'react-password-checklist';
import {
  DataGrid,
  GridCellModes,
  GridToolbar,
  GridActionsCellItem,
} from '@mui/x-data-grid';

//Icon Styles
const styles = {
  colors: {
    primary: {
      color: '#007bff',
    },
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'table-cell',
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif',
  },
  table: {
    maxHeight: '400px !important',
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff',
    },
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366',
    },
    save: {
      color: '#10b759',
      cursor: 'pointer',
    },
    cancel: {
      color: '#fbbc06',
      cursor: 'pointer',
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer',
    },
    danger: {
      color: '#ff3366',
      cursor: 'pointer',
    },
  },
  alignMiddle: {
    alignItems: 'center',
  },
  spinner: {
    height: '5rem',
    width: '5rem',
  },
};

const formatDate = (d) => {
  let date = new Date(d);
  let dateFormatted = date.toLocaleDateString();
  let timeFormatted = date.toLocaleTimeString();
  return `${dateFormatted} ${timeFormatted}`;
};

const formatPhone = (phone) => {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

const validateUser = (user) => {
  if (user.firstName?.length <= 0) {
    return { valid: false, error: 'First name cannot be empty.' };
  }

  if (user.lastName?.length <= 0) {
    return { valid: false, error: 'Last name cannot be empty.' };
  }

  if (!(user.authLevel >= 1 && user.authLevel <= 10)) {
    return {
      valid: false,
      error: 'Authorization level must be between 1 and 10.',
    };
  }

  return { valid: true, error: null };
};

const useUpdateUser = () => {
  return useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        let { valid, error } = validateUser(user);
        if (!valid) {
          reject(error);
        } else {
          user.userId = user.id;
          sendData('updateUser', user);
          resolve({ ...user });
        }
      }),
    []
  );
};

function ManageUsers(props) {
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [userUpdateId, setUserUpdateId] = useState(null);
  const [resetUserId, setResetUserId] = useState(null);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [authLevel, setAuthLevel] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [meetsRequirements, setMeetsRequirements] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    body: '',
    confirm: true,
    confirmText: 'Confirm',
    confirmHandler: () => {},
    cancel: true,
    cancelText: 'Cancel',
  });
  const [cellModesModel, setCellModesModel] = useState({});
  const [groups, setGroups, groupsRef] = useState([]);
  const [columns, setColumns] = useState([
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      type: 'actions',
      getActions: ({ row }) => {
        let actions = [];

        actions.push(
          <GridActionsCellItem
            icon={<i className="fa-solid fa-users-medical"></i>}
            label="Add User to Group"
            title="Edit Groups"
            onClick={() => startUpdateUserGroups(row.id)}
          />
        );

        actions.push(
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faKey} size="sm" />}
            label="Password"
            title="Edit Password"
            onClick={() => startUpdateUserPassword(row.id)}
          />
        );

        if (row.active) {
          actions.push(
            <GridActionsCellItem
              icon={
                <FontAwesomeIcon
                  style={styles.icons.danger}
                  icon={faTimesCircle}
                  size="sm"
                />
              }
              label="Deactivate"
              title="Deactivate User"
              onClick={() => startDeactivation(row.id)}
            />
          );
        } else {
          actions.push(
            <GridActionsCellItem
              icon={
                <FontAwesomeIcon
                  style={styles.icons.save}
                  icon={faRedo}
                  size="sm"
                />
              }
              label="Reactivate"
              title="Reactivate User"
              onClick={() => startReactivation(row.id)}
            />
          );
        }

        return actions;
      },
      editable: false,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      type: 'string',
      width: 150,
      valueFormatter: ({ value }) => formatPhone(value),
      editable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
      type: 'string',
      width: 250,
      editable: true,
    },
    {
      headerName: 'Auth Level',
      field: 'authLevel',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      headerName: 'Last Active',
      field: 'lastActive',
      type: 'string',
      width: 200,
      valueFormatter: ({ value }) => formatDate(value),
      editable: false,
    },
  ]);

  const handleShowModal = () => setShowModal(!showModal);
  const handleShowPasswordResetModal = () =>
    setShowPasswordResetModal(!showPasswordResetModal);

  const updateUser = useUpdateUser();

  const processRowUpdate = useCallback(
    async (user) => {
      // Make the HTTP request to save in the backend
      const response = await updateUser(user);
      return response;
    },
    [updateUser]
  );

  const onProcessRowError = useCallback((err) => {
    setModalContent({
      title: 'Error Updating User',
      body: err,
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();
  });

  const handleCellClick = useCallback((params) => {
    if (params.isEditable) {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const getGroups = () => {
    sendData('getGroups');
  };

  const onGetGroups = (data) => {
    setGroups(data);
  };

  const getUsers = () => {
    let getUsersData = {
      genUserId: localStorage.getItem('genUserId'),
    };

    sendData('getUsers', getUsersData);
  };

  /**
   * Handle response to getting users
   * associated with the company
   * @param {JSON} data response data
   */
  const onGetUsers = async (data) => {
    if (!data[0].error) {
      setLoading(false);

      for (let i = 0; i < data.length; i++) {
        let shiftedDate = data[i].lastActive.replace('Z', '-07:00');
        data[i].lastActive = shiftedDate;
      }

      setUsers(data);
    } else {
      setLoading(true);
    }
  };

  const startUpdateUserGroups = (userId) => {
    sendData('getUserGroups', { userId: userId, active: true });
  };

  const onGetUserGroups = ({ userGroups, userId }) => {
    if (userGroups.length > 0) {
      setModalContent({
        title: "Manage User's Groups",
        body: (
          <Form>
            <Form.Group>
              {groupsRef.current.map((group) => {
                let isChecked = userGroups.some((element) => {
                  if (element.id === group.id) {
                    return true;
                  }

                  return false;
                });

                return (
                  <Form.Check
                    id={group.id}
                    key={group.id}
                    type="checkbox"
                    label={group.name}
                    name="usergroups"
                    defaultChecked={isChecked}
                    onChange={(e) =>
                      toggleUserGroup(e.target.checked, group.id, userId)
                    }
                  />
                );
              })}
            </Form.Group>
          </Form>
        ),
        confirm: false,
        cancel: true,
        cancelText: 'Close',
      });
    } else {
      setModalContent({
        title: 'Error',
        body: 'There are no active groups to add this user to.',
        confirm: false,
        cancel: true,
        cancelText: 'Close',
      });
    }

    handleShowModal();
  };

  const startDeactivation = (userId) => {
    setModalContent({
      title: 'Deactivate User',
      body: 'Are you sure you want to deactive this user?',
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => deactivateUser(userId),
      cancel: true,
      cancelText: 'Cancel',
    });
    handleShowModal();
  };

  const deactivateUser = (userId) => {
    sendData('deactivateUser', {
      genUserId: localStorage.getItem('genUserId'),
      userId: userId,
    });
  };

  /**
   * Handles response to deactivating a
   * user in Daily Stream
   * @param {JSON} data response data
   */
  const onDeactivateUser = (data) => {
    //Close deactivation confirmation modal
    handleShowModal();

    //Create new success modal
    setModalContent({
      title: 'Success',
      body: 'User has been deactivated',
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();

    //Refresh users
    getUsers();
  };

  const addUser = (e) => {
    e.preventDefault();

    let user = {
      authLevel: authLevel,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      confirmPassword: confirmPassword,
      customerId: localStorage.getItem('companyId'),
      genUserId: localStorage.getItem('genUserId'),
    };

    let { valid, error } = validateUser(user);

    if (valid) {
      if (meetsRequirements) {
        sendData('addUser', user);
      } else {
        setModalContent({
          title: 'Error',
          body: 'Password must meet the requirements.',
          confirm: false,
          cancel: true,
          cancelText: 'Close',
        });
        handleShowModal();
      }
    } else {
      setModalContent({
        title: 'Error',
        body: error,
        confirm: false,
        cancel: true,
        cancelText: 'Close',
      });
      handleShowModal();
    }
  };

  /**
   * Handles response to adding
   * a new user
   * @param {JSON} data response data
   */
  const onAddUser = (data) => {
    setModalContent({
      title: 'Success',
      body: 'New user has been registered!',
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();

    setIsAddingUser(false);

    setAuthLevel(1);
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');

    getUsers();
  };

  /**
   * Handles response to updating
   * user
   * @param {JSON} data response data
   */
  const onUpdateUser = () => {
    setModalContent({
      title: 'Success',
      body: 'User has been updated!',
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();

    getUsers();
  };

  const startUpdateUserPassword = (userId) => {
    setResetUserId(userId);
    setPassword('');
    setConfirmPassword('');
    setShowPassword(false);

    handleShowPasswordResetModal();
  };

  const updateUserPassword = (userId) => {
    handleShowPasswordResetModal();

    if (meetsRequirements) {
      sendData('updateUserPassword', {
        genUserId: localStorage.getItem('genUserId'),
        userId: userId,
        password: password,
      });
    } else {
      setModalContent({
        title: 'Error',
        body: 'Password must meet the requirements.',
        confirm: false,
        cancel: true,
        cancelText: 'Close',
      });
      handleShowModal();
    }
  };

  /**
   * Handles response to updating
   * a user's password
   * @param {JSON} data response data
   */
  const onUpdateUserPassword = (data) => {
    setModalContent({
      title: 'Success',
      body: "User's password successfully updated!",
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();

    setPassword('');
    setConfirmPassword('');
    setMeetsRequirements(false);
  };

  const reactivateUser = (userId) => {
    sendData('reactivateUser', {
      genUserId: localStorage.getItem('genUserId'),
      userId: userId,
    });
  };

  /**
   * Handles response to reactivating a
   * user in Daily Stream
   * @param {JSON} data response data
   */
  const onReactivateUser = (data) => {
    //Close reactivation modal
    handleShowModal();

    setModalContent({
      title: 'Success',
      body: 'User has been reactivated.',
      confirm: false,
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();

    getUsers();
  };

  const startReactivation = (userId) => {
    setModalContent({
      title: 'Reactivate User',
      body: 'Are you sure you want to reactivate this user?',
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => reactivateUser(userId),
      cancel: true,
      cancelText: 'Close',
    });
    handleShowModal();
  };

  const toggleUserGroup = (isChecked, groupId, userId) => {
    if (isChecked) {
      sendData('addUserToGroup', {
        userId: userId,
        groupId: groupId,
      });
    } else {
      sendData('deleteUserFromGroup', {
        userId: userId,
        groupId: groupId,
      });
    }
  };

  const onDeleteUserFromGroup = (data) => {
    //Display toast notification in future
  };

  const onAddUserToGroup = (data) => {
    //Display toast notification in future
  };

  useEffect(() => {
    getGroups();
    getUsers();

    onData('getUsers', onGetUsers);
    onData('addUser', onAddUser);
    onData('updateUser', onUpdateUser);
    onData('updateUserPassword', onUpdateUserPassword);
    onData('deactivateUser', onDeactivateUser);
    onData('reactivateUser', onReactivateUser);
    onData('getGroups', onGetGroups);
    onData('getUserGroups', onGetUserGroups);
    onData('deleteUserFromGroup', onDeleteUserFromGroup);
    onData('addUserToGroup', onAddUserToGroup);

    return () => {
      offData('getUsers');
      offData('addUser');
      offData('updateUser');
      offData('updateUserPassword');
      offData('deactivateUser');
      offData('reactivateUser');
      offData('getGroups');
      offData('getUserGroups');
      offData('deleteUserFromGroup');
      offData('addUserToGroup');
    };
  }, []);

  return (
    <>
      <Modal style={styles.font} show={showModal} onHide={handleShowModal}>
        <Modal.Header>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.body}</Modal.Body>
        {modalContent.confirm || modalContent.cancel ? (
          <Modal.Footer>
            {modalContent.cancel && (
              <Button variant="danger" onClick={handleShowModal}>
                {modalContent.cancelText}
              </Button>
            )}
            {modalContent.confirm && (
              <Button variant="primary" onClick={modalContent.confirmHandler}>
                {modalContent.confirmText}
              </Button>
            )}
          </Modal.Footer>
        ) : null}
      </Modal>

      <Modal
        style={styles.font}
        show={showPasswordResetModal}
        onHide={handleShowPasswordResetModal}
      >
        <Modal.Header>
          <Modal.Title>Reset User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Password</Form.Label>
          <InputGroup size="sm" className="mb-3">
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Password"
              name="password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputGroup.Prepend>
              <Button
                variant="outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="-1"
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={showPassword ? faEye : faEyeSlash}
                />
              </Button>
            </InputGroup.Prepend>
          </InputGroup>
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup size="sm" className="mb-3">
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <InputGroup.Prepend>
              <Button
                variant="outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="-1"
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={showPassword ? faEye : faEyeSlash}
                />
              </Button>
            </InputGroup.Prepend>
          </InputGroup>
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={(isValid) => {
              setMeetsRequirements(isValid);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleShowPasswordResetModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => updateUserPassword(resetUserId)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {isAddingUser ? (
        <>
          <Row>
            <Col>
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="lg"
                style={{ margin: '10px', cursor: 'pointer' }}
                onClick={() => setIsAddingUser(!isAddingUser)}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Card>
                <Card.Header>Add New User</Card.Header>
                <Card.Body>
                  <Form onSubmit={addUser}>
                    <Form.Label>Auth Level</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type="number"
                        autoComplete="off"
                        min="1"
                        max="10"
                        step="1"
                        name="authLevel"
                        value={authLevel}
                        onChange={(e) => setAuthLevel(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <Form.Label>First name</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <Form.Label>Last name</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <Form.Label>Email</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <Form.Label>Phone</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type="tel"
                        autoComplete="off"
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </InputGroup>
                    <Form.Label>Password</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroup.Prepend>
                        <Button
                          variant="outline-secondary"
                          onClick={() => setShowPassword(!showPassword)}
                          tabIndex="-1"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={showPassword ? faEye : faEyeSlash}
                          />
                        </Button>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup size="sm" className="mb-2">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <InputGroup.Prepend>
                        <Button
                          variant="outline-secondary"
                          onClick={() => setShowPassword(!showPassword)}
                          tabIndex="-1"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={showPassword ? faEye : faEyeSlash}
                          />
                        </Button>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <PasswordChecklist
                      rules={[
                        'minLength',
                        'specialChar',
                        'number',
                        'capital',
                        'match',
                      ]}
                      minLength={8}
                      value={password}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => {
                        setMeetsRequirements(isValid);
                      }}
                    />
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Header>
                Manage Users
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip placement="bottom" id="button-tooltip-1">
                      Add User
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      ref={ref}
                      onClick={() => setIsAddingUser(true)}
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        style={styles.colors.primary}
                        pull="right"
                      />
                    </Button>
                  )}
                </OverlayTrigger>
                {/* <Button 
                size="sm"
                onClick={() => {props.setTabIndex(0)}}
              >
                Manage Single Sign-On Domains
              </Button> */}
              </Card.Header>

              {isLoading ? (
                <Loader />
              ) : (
                <Card.Body style={{ height: '500px' }}>
                  <DataGrid
                    columns={columns}
                    rows={users}
                    components={{ Toolbar: GridToolbar }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={onProcessRowError}
                    cellModesModel={cellModesModel}
                    onCellModesModelChange={handleCellModesModelChange}
                    onCellClick={handleCellClick}
                    experimentalFeatures={{ newEditingApi: true }}
                    disableSelectionOnClick
                  />
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ManageUsers;
