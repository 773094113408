import { compose, withState, withHandlers } from 'react-recompose';
import { sendData } from '../../Hooks/Socket';
import Viewer from '../Viewer';

export default function provideExampleValue(value) {
  const submitComment = (newValue, taggedArray, idArray) => {
    let regex1 = /@\[[^\]]*]\{\d*\}/g;
    let arrMatches = [];
    if (newValue) {
      newValue = String(newValue);
      arrMatches = newValue.match(regex1);

      if (arrMatches) {
        for (let i = 0; i < arrMatches.length; i++) {
          let replacementStr = arrMatches[i].match(/@\[[^\]]*]/g)[0];
          replacementStr = replacementStr.replace('[', '');
          replacementStr = replacementStr.replace(']', '');
          newValue = newValue.replace(arrMatches[i], replacementStr);
        }
      }
    }

    let timeOffset;
    let isClipComment = Viewer.page === 'clip' ? true : false;

    if (isClipComment) {
      try {
        let video1 = document.getElementById(`video1-1`);

        timeOffset = video1.currentTime;
      } catch (err) {
        console.error(err);
        timeOffset = 0;
      }
    } else {
      try {
        const player = document.getElementById('player');
        player.contentWindow.postMessage(
          {
            event: 'comment',
            taggedList: taggedArray,
            idArray: idArray,
            id: isClipComment ? Viewer.clipId : Viewer.urlUser,
            timestamp: new Date(),
            streamDate: isClipComment ? null : Viewer.getCustomDay(),
            isClip: isClipComment,
            clipUID: isClipComment ? Viewer.clipUID : null,
            genUserId: localStorage.getItem('genUserId'),
            comment: newValue,
          },
          window.playerSite
        );
      } catch (err) {
        console.error(err);
      }
    }
  };
  return compose(
    withState('value', 'setValue', value),
    withHandlers({
      onChange:
        ({ setValue }) =>
        (ev, newValue) => {
          const value = ev.target.value;
          const regex = /[^{}]+(?=})/g;
          const mentions = value.match(regex);

          //Check for new line
          let match = /\r|\n/.exec(ev.target.value);
          if (match) {
            let taggedArray = null;
            if (mentions) {
              taggedArray = [...mentions];
              taggedArray[0] = `(${taggedArray[0]}`;
              taggedArray[taggedArray.length - 1] = `${
                taggedArray[taggedArray.length - 1]
              })`;
              taggedArray = taggedArray.toString();
            }

            submitComment(value, taggedArray, mentions);
            setValue('');
          } else {
            setValue(newValue);
          }
        },
      onAdd:
        () =>
        (...args) => {},
      onRemove:
        () =>
        (...args) => {},
    })
  );
}
