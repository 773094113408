import {
  faBookmark,
  faDesktop,
  faFilm,
  faLink,
  faStop,
  faWindowClose,
  faExclamationCircle,
  faUserCog,
  faFileInvoice,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendData, onData, offData } from '../Hooks/Socket';
import Auth from '../Components/Auth';
import React, { useEffect, useState, useRef } from 'react';
import Idle from '../Components/Idle';
import Users from '../Components/Users';
import {
  NavLink,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Recorder from '../Components/Recorder';
import Viewer from '../Components/Viewer';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mentions from '../Components/Mentions';
import '../Assets/css/navbar.css';
import MultipleTrigger from '../Components/MultipleTrigger';
import ShareClipMentions from '../Components/ShareClipMentions';

const styles = {
  colors: {
    primary: {
      color: '#007bff',
    },
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'table-cell',
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif',
  },
  table: {
    maxHeight: '400px !important',
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff',
    },
  },
  icons: {
    connected: {
      color: '#10b759',
    },
    disconnected: {
      color: '#ff3366',
    },
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px',
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer',
    },
    edit: {
      color: '#007bff',
      fontSize: '1.2rem',
      cursor: 'pointer',
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer',
    },
  },
  alignMiddle: {
    alignItems: 'center',
  },
  spinner: {
    height: '5rem',
    width: '5rem',
  },
};

const confirmExit = () => {
  if (Recorder.isStreaming) {
    sendData('streamReminderReport', { event: 5 });
    return 'You have attempted to leave this page. Are you sure?';
  }
};
window.onbeforeunload = confirmExit;

function NavBar() {
  const authLevel = parseInt(localStorage.getItem('authLevel'));
  // const notified = Auth.getNotified()
  const notified = true;
  const [bookmarkName, setBookmarkName] = useState(0);
  const [streamIcon, setStreamIcon] = useState(faDesktop);
  const [broadCastMessage, setBroadCastMessage] = useState(0);
  const [clipIcon, setClipIcon] = useState(faFilm);
  const [clipIconColor, setClipIconColor] = useState('black');
  const [clipTooltip, setClipTooltip] = useState('Start Clip');
  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState({
    text: '',
    delay: 5000,
    autoHide: false,
  });
  const [showBookmarkButton, setShowBookmarkButton] = useState(false);
  const [tags, setTags] = useState(0);
  const [showBookmarkModal, setShowBookmarkModal] = useState(false);
  var [mentions, setMentions] = useState([]);
  const handleOpenBookmarkModal = () => setShowBookmarkModal(true);
  const handleCloseBookmarkModal = () => setShowBookmarkModal(false);
  const [showBookmarkWarningModal, setshowBookmarkWarningModal] =
    useState(false);
  const handleCloseBookmarkWarningModal = () =>
    setshowBookmarkWarningModal(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({
    title: '',
    text: '',
    confirmButton: '',
    confirm: null,
    confirmHandler: null,
    cancelButton: '',
    cancel: null,
    static: false,
    keyboard: true,
    hideEvent: null,
  });

  const location = useLocation();
  const history = useHistory();

  let clipName = '';

  const handleShowModal = () => setShowModal(!showModal);

  const handleCloseNotification = () => {
    handleShowModal();

    Auth.setNotified(true);
  };

  const onExtMessage = (message) => {
    if (document?.extPage) {
      switch (message.event) {
        case 'startBroadcast':
          streamController();
          break;
        case 'stopBroadcast':
          streamController();
          break;
        case 'startClip':
          clipController();
          break;
        case 'stopClip':
          clipController();
          break;
        default:
          break;
      }
    }
  };

  const renderTooltip = (params) => {
    return <Tooltip id="button_tooltip">{params.message}</Tooltip>;
  };

  const updateRecordingDisplay = (start = true, recordingType = 'both') => {
    if (start) {
      if (recordingType === 'stream') {
        setBroadCastMessage('Stop Broadcast');
        setStreamIcon(faWindowClose);
        setBroadCastMessage('Broadcast your screens');
      } else if (recordingType === 'clip') {
        setClipIcon(faStopCircle);
        setClipIconColor('#dc3545');
        setClipTooltip('Stop Clip');
      } else {
        setBroadCastMessage('Stop Broadcast');
        setStreamIcon(faWindowClose);
        setClipIcon(faStopCircle);
        setClipIconColor('#dc3545');
        setClipTooltip('Stop Clip');
      }
    } else {
      if (recordingType === 'stream') {
        setStreamIcon(faDesktop);
        setBroadCastMessage('Broadcast your screens');
      } else if (recordingType === 'clip') {
        setClipIcon(faFilm);
        setClipIconColor('black');
        setClipTooltip('Start Clip');
      } else {
        setStreamIcon(faDesktop);
        setBroadCastMessage('Broadcast your screens');
        setClipIcon(faFilm);
        setClipIconColor('black');
        setClipTooltip('Start Clip');
      }
    }
  };

  const promptStartClipName = () => {
    setModal({
      title: 'Create Clip',
      text: (
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Clip Name"
            onChange={(e) => (clipName = e.target.value)}
          />
        </Form.Group>
      ),
      confirm: true,
      confirmButton: 'Save',
      confirmHandler: promptStartClipMonitors,
      cancel: true,
      cancelButton: 'Cancel',
    });
    handleShowModal();
  };

  const promptStartClipMonitors = () => {
    if (clipName) {
      setModal({
        title: 'Number of Monitors',
        text: (
          <div className="d-flex justify-content-center">
            <Button
              id="monitor1"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(1, true)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
            <Button
              id="monitor2"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(2, true)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
            <Button
              id="monitor3"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(3, true)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
          </div>
        ),
        confirm: true,
        confirmButton: 'Confirm',
        confirmHandler: saveClip,
        cancel: true,
        cancelButton: 'Cancel',
      });
    } else {
      setModal({
        title: 'Error',
        text: 'Please enter a clip name',
        confirm: false,
        cancel: true,
        cancelButton: 'Close',
      });
    }
    handleShowModal();
  };

  const saveClip = () => {
    setShowModal(false);

    sendData('startClipping');
    Recorder.startClipping(clipName);
  };

  const promptCopyClip = (clipId, clipUID) => {
    setToast({
      text: (
        <Form>
          <FormLabel>Clip Link</FormLabel>
          <InputGroup size="sm">
            <FormControl
              type="text"
              placeholder="Clip Url"
              value={`${window.location.origin}/clip/${clipId}/${clipUID}`}
              readOnly
            />
            <InputGroup.Prepend>
              <OverlayTrigger
                placement="top"
                trigger="click"
                delay={{ hide: 400 }}
                overlay={renderTooltip({ message: 'Copied!' })}
              >
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      `${window.location.origin}/clip/${clipId}/${clipUID}`
                    );
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faLink} />
                </Button>
              </OverlayTrigger>
            </InputGroup.Prepend>
          </InputGroup>
        </Form>
      ),
      delay: 10000,
      autoHide: true,
    });

    setShowToast(true);
  };

  const promptStopClip = () => {
    setModal({
      title: 'Stop Clip',
      text: (
        <Row className="align-items-center justify-content-center text-center mb-3">
          <ShareClipMentions data={Viewer.userList} />
        </Row>
      ),
      confirm: true,
      confirmButton: 'Save to Library',
      cancel: true,
      confirmHandler: stopClipping,
      cancelButton: 'Cancel',
    });
    handleShowModal();
  };

  const stopClipping = () => {
    setShowModal(false);

    setToast({
      text: (
        <>
          <p>Saving clip...</p>
          <LinearProgress />,
        </>
      ),
      delay: null,
      autoHide: false,
    });
    setShowToast(true);

    sendData('stopClipping');

    Recorder.stopClipping();
  };

  const clipController = (clipping) => {
    if (Recorder.isClip) {
      promptStopClip();
    } else {
      promptStartClipName();
    }
  };

  const promptStopStreaming = () => {
    setModal({
      title: 'Stop Stream',
      text: (
        <>
          <Row className="align-items-center justify-content-center text-center mb-3">
            <Col>
              <FontAwesomeIcon
                size="4x"
                icon={faExclamationCircle}
                style={styles.icons.danger}
                spin
              />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center text-center mb-auto">
            <Col>Are you sure you would like to stop streaming?</Col>
          </Row>
        </>
      ),
      confirm: true,
      confirmButton: 'Confirm',
      confirmHandler: stopStream,
      cancel: true,
      cancelButton: 'Cancel',
    });
    handleShowModal();
  };

  const stopStream = () => {
    setShowModal(false);

    Recorder.stopStreaming();
  };

  const startStream = () => {
    setShowModal(false);

    sendData('startBroadcast');

    Recorder.startStreaming();
  };

  const streamController = () => {
    if (Recorder.isStreaming) {
      promptStopStreaming();
    } else {
      setModal({
        title: 'Number of Monitors',
        text: (
          <div className="d-flex justify-content-center">
            <Button
              id="monitor1"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(1, false)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
            <Button
              id="monitor2"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(2, false)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
            <Button
              id="monitor3"
              variant="outline-secondary"
              className="mr-2"
              onClick={() => setNumMonitors(3, false)}
            >
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
              <FontAwesomeIcon icon={faDesktop} size="4x" />
            </Button>
          </div>
        ),
        confirm: true,
        confirmButton: 'Confirm',
        confirmHandler: startStream,
        cancel: true,
        cancelButton: 'Cancel',
      });
      handleShowModal();
    }
  };

  const setNumMonitors = (num, isClip) => {
    if (isClip) {
      Recorder.setClipNumMonitors(num);
    } else {
      Recorder.setStreamNumMonitors(num);
    }

    let prevSelectedMonitor =
      document.getElementsByClassName('monitor-selected')[0];
    let curSelectedMonitor = document.getElementById(`monitor${num}`);

    if (prevSelectedMonitor) {
      prevSelectedMonitor.classList.remove('monitor-selected');
    }
    if (curSelectedMonitor) {
      curSelectedMonitor.classList.add('monitor-selected');
    }
  };

  const clipError = (error, stopClip) => {
    if (stopClip) {
      setClipIcon(faFilm);
      setClipIconColor('black');
      setClipTooltip('Start Clip');
    }

    setModal({
      title: 'Clip Error',
      text: error,
      confirm: false,
      cancel: true,
      cancelButton: 'Close',
      static: false,
    });
    handleShowModal();
  };

  const streamError = (error, stopBroadcast) => {
    if (stopBroadcast) {
      setStreamIcon(faDesktop);
    }

    setModal({
      title: 'Stream Error',
      text: error,
      confirm: false,
      cancel: true,
      cancelButton: 'Close',
      static: false,
    });
    handleShowModal();
  };

  const getLastStreamPosition = () => {
    if (!Recorder.currentDay) {
      Recorder.currentDay =
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + new Date().getDate()).slice(-2) +
        '-' +
        new Date().getFullYear();

      let data = {
        userId: localStorage.getItem('userId'),
        date: Recorder.currentDay,
        reset: true,
      };
      sendData('getLastStreamPosition', data);
    } else {
      let tempDay =
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + new Date().getDate()).slice(-2) +
        '-' +
        new Date().getFullYear();

      if (tempDay != Recorder.currentDay) {
        let data = {
          userId: localStorage.getItem('userId'),
          date: tempDay,
          reset: true,
        };
        sendData('getLastStreamPosition', data);
      } else {
        let data = {
          userId: localStorage.getItem('userId'),
          date: Recorder.currentDay,
          reset: false,
        };
        sendData('getLastStreamPosition', data);
      }
    }
  };

  const onGetLastStreamPosition = (data) => {
    let streamPosition = parseInt(data.streamPosition);

    if (streamPosition > 0) {
      ++streamPosition;
    }

    Recorder.streamPosition = streamPosition;
  };

  const saveBookmark = (e) => {
    e.preventDefault();
    Viewer.bookMark(bookmarkName);
    handleCloseBookmarkModal(false);
  };

  const bookmarkController = () => {
    if (Viewer.userId) {
      handleOpenBookmarkModal();
    } else {
      setModal({
        title: 'Error',
        text: (
          <>
            <Row className="align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="lg"
                  icon={faExclamationCircle}
                  style={styles.icons.danger}
                  spin
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-center mb-auto">
              <Col>You must be streaming to start clipping!</Col>
            </Row>
          </>
        ),
        confirm: false,
        cancel: false,
      });
      handleShowModal();
    }
  };

  /**
   * Handles error response from server
   * @param {JSON} data error response data
   * @param {String} data.error the error message
   */
  const onError = (data) => {
    setModal({
      title: 'Error!',
      text: data.error,
      confirm: false,
      cancel: true,
      cancelButton: 'Close',
    });
    handleShowModal();
  };

  useEffect(() => {
    //Set Recorder error handler for streams
    Recorder.updateRecordingDisplay = updateRecordingDisplay;
    Recorder.clipError = clipError;
    Recorder.streamError = streamError;
    Recorder.promptCopyClip = promptCopyClip;

    Viewer.getUserList();

    setBroadCastMessage('Broadcast your screens');
    if (!notified) {
      setModal({
        title: 'Please Acknowledge the Following',
        text: (
          <>
            <p>
              You acknowledge and consent to screen and audio monitoring while
              using Daily Stream. By recording your screens and microphones
              connected to your work computer your employer or Daily Stream
              support technicians may monitor, record, collect, and track the
              following categories of information if it appears on your work
              computer:
            </p>
            <ul>
              <li>
                Live video that may record your face, desk, workspace, and voice
              </li>
              <li>On call and off call audio</li>
              <li>Website visits</li>
              <li>Emails</li>
              <li>Keystrokes</li>
              <li>File Transfers</li>
              <li>Printing</li>
              <li>Application Usage</li>
              <li>Social Media</li>
              <li>Instant Messages</li>
            </ul>
            <h4>
              <strong>
                <u>Reasonable Expectation of Privacy</u>
              </strong>
            </h4>
            <p>
              If you are using employer-owned equipment, you acknowledge that
              you have no reasonable expectation of privacy in any message,
              file, data, document, conversation, or any other kind of form of
              information or communication transmitted to, received, print from,
              stored or recorded on your employer’s equipment.
              <br />
              <u>
                This is the same expectation of privacy which you would
                reasonably expect while working on-site for your employer.
              </u>
            </p>
            <h4>
              <strong>
                <u>Your Obligations</u>
              </strong>
            </h4>
            <p>
              You also agree that, while using our software, it is your sole
              responsibility to ensure other members of your household are not
              subject to recording or monitoring without their consent. You must
              make sure that only you will be recorded and monitored while
              logged into the software. You must make sure that no one else will
              be physically in the room while you are logged in and that no one
              else speaks to you while you are logged into the software.
            </p>
          </>
        ),
        confirm: true,
        confirmButton: 'Confirm',
        cancel: false,
        onHide: handleCloseNotification,
        static: true,
        keyboard: false,
      });
      handleShowModal();
    }

    onData('error', onError);
    onData('getLastStreamPosition', onGetLastStreamPosition);
    onData('extMessage', onExtMessage);

    getLastStreamPosition();

    // Users.getActiveUsers();

    return () => {
      offData('error');
      offData('getLastStreamPosition');
      offData('extMessage');
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/ext/startBroadcast') {
      document.extPage = true;
      streamController();
    } else if (location.pathname === '/ext/startClip') {
      document.extPage = true;
      clipController();
    }
  }, [location]);

  return (
    <div>
      <Modal
        backdrop={modal.static ? 'static' : true}
        keyboard={modal.keyboard ? true : false}
        show={showModal}
        onHide={modal.hideEvent ? modal.hideEvent : handleShowModal}
      >
        <Modal.Header>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.text}</Modal.Body>
        {modal.confirm || modal.cancel ? (
          <Modal.Footer>
            {modal.confirm ? (
              <Button
                variant="primary"
                onClick={
                  modal.confirmHandler ? modal.confirmHandler : handleShowModal
                }
              >
                {modal.confirmButton}
              </Button>
            ) : null}
            {modal.cancel ? (
              <Button variant="danger" onClick={handleShowModal}>
                {modal.cancelButton}
              </Button>
            ) : null}
          </Modal.Footer>
        ) : null}
      </Modal>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: 'absolute',
          top: 60,
          right: 0,
          zIndex: 1000,
        }}
        delay={toast ? toast.delay : null}
        autohide={toast.autoHide}
      >
        <Toast.Header closeButton={true}>
          <img
            src="https://cdn.dailystream.com/images/48.png"
            className="mr-2"
            alt=""
            width="32px"
            height="32px"
          />
          <strong className="me-auto">Daily Stream</strong>
        </Toast.Header>
        <Toast.Body>{toast.text}</Toast.Body>
      </Toast>

      {/* <Modal 
			variant="primary" 
			show={showBookmarkWarningModal} 
			onHide={handleCloseBookmarkWarningModal}
		>
			<Modal.Header closeButton>
			<Modal.Title>No User Selected</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<p>You must be on a user page to bookmark.</p>
			</Modal.Body>
			<Modal.Footer>
			<Button 
				variant="primary" 
				onClick={handleCloseBookmarkWarningModal}
			>Close</Button>
			</Modal.Footer>
		</Modal> */}
      {/* <Modal 
			variant="primary" 
			show={showBookmarkModal} 
			onHide={handleCloseBookmarkModal}
		>
			<Modal.Header closeButton>
			<Modal.Title>Create Bookmark</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<Form.Group>
				<Form.Control 
				type="text" 
				placeholder="Enter bookmark name" 
				onChange={e => setBookmarkName(e.target.value)}
				/>
			</Form.Group>
			</Modal.Body>
			<Modal.Footer>
			<Button 
				variant="secondary" 
				onClick={handleCloseBookmarkModal}
			>Cancel</Button>
			<Button 
				variant="primary" 
				onClick={saveBookmark}
			>Save</Button>
			</Modal.Footer>
		</Modal> */}

      {/* Daily Stream Navigation Bar */}
      <Navbar bg="light" variant="light" className="justify-content-between">
        <Nav className="mr-auto">
          <Navbar.Brand
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => history.push('/channels')}
          >
            <span>Daily</span>
            <span style={{ fontWeight: '900', color: '#2f2f2f' }}>Stream</span>
          </Navbar.Brand>
          <NavLink to="/channels" className="nav-link">
            <span className="menu-title">Channels</span>
          </NavLink>
          {/* <NavLink to="/search" className="nav-link" onClick={(e) => e.preventDefault()}>
				<span className="menu-title" title="Coming Soon">Search</span>
			</NavLink>
			 */}
          {authLevel >= 2 ? (
            <NavLink to="/reports" className="nav-link" title="Reports">
              <span className="menu-title" title="Reports">
                Reports
              </span>
            </NavLink>
          ) : null}
          <NavLink to="/library/0" className="nav-link">
            <span className="menu-title">Library</span>
          </NavLink>
          <NavLink to="/account" className="nav-link">
            <span className="menu-title">User Settings</span>
          </NavLink>
          {authLevel >= 8 ? (
            <NavLink to="/admin" className="nav-link">
              <span className="menu-title">Admin Settings</span>
            </NavLink>
          ) : null}
        </Nav>
        <Nav className="justify-content-end align-items-center">
          <Mentions />
          {/* <Col>
				<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip placement="bottom" id="button-tooltip-2">Bookmark</Tooltip>}
				>
				{({ ref, ...triggerHandler }) => (
					<Button
					id="bookmarkButton"
					variant="light"
					{...triggerHandler}
					className="d-inline-flex align-items-center"
					ref={ref}
					onClick={bookmarkController} 
					>
					<FontAwesomeIcon icon={faBookmark} />
					</Button>
				)}
				</OverlayTrigger>
			</Col> */}
          <Col style={{ marginRight: '1rem' }}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip placement="bottom" id="button-tooltip-3">
                  {clipTooltip}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  id="clipButton"
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                  ref={ref}
                  onClick={clipController}
                >
                  <FontAwesomeIcon
                    icon={clipIcon}
                    style={{ color: clipIconColor }}
                    size="lg"
                  />
                </Button>
              )}
            </OverlayTrigger>
          </Col>
          <Col style={{ marginRight: '1rem' }}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip placement="bottom" id="button-tooltip-1">
                  Broadcast
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                  ref={ref}
                  onClick={streamController}
                >
                  <FontAwesomeIcon icon={streamIcon} size="lg" />
                </Button>
              )}
            </OverlayTrigger>
          </Col>
          <Col style={{ marginRight: '1rem' }}>
            <Link className="ml-4" to="/logout">
              <Button variant="primary">Logout</Button>
            </Link>
          </Col>
        </Nav>
      </Navbar>

      {/* Idle Tracking */}
      <Idle />
    </div>
  );
}

export default NavBar;
