function logger(
  type,
  file,
  errorCode,
  message,
  info = {},
  log = true,
  clear = false
) {
  if (log) {
    if (clear) {
      console.clear();
    }

    if (type === 'error') {
      //console.error(message);
      //console.trace();
    }

    if (type === 'warn') {
      console.warn(message);
    }

    if (type === 'info') {
      console.info(message);
    }

    if (type === 'log') {
      console.log(message);
    }
  }

  logData(type, file, errorCode, message, info);
}

const logData = (type, file, errorCode, message, info) => {
  try {
    if (!window.lastErrorTimes) {
      window.lastErrorTimes = new Map();
    }
    const currentTime = Date.now();

    if (
      window.lastErrorTimes.has(errorCode) &&
      currentTime - window.lastErrorTimes.get(errorCode) < 60000
    ) {
      return;
    }
    window.lastErrorTimes.set(errorCode, currentTime);

    let notes =
      message && message.message ? message.message : message.toString();

    const logData = {
      genUserId: localStorage.getItem('genUserId') || 0,
      userId: localStorage.getItem('userId') || 0,
      type: type || 'unknown',
      file: file || 'unknown',
      errorCode: errorCode || 0,
      notes: notes || '',
      currentState: info?.isStreaming ? 'streaming' : 'idle',
      frame: info?.streamPosition ?? -1, // Using nullish coalescing for clarity
      idleTrackingRequired: info?.idleTrackingRequired
        ? 'required'
        : 'not required',
      idleTracking: info?.idleTracking ? 'active' : 'inactive',
      idleGrant: info?.idleGrant ? 'granted' : 'not granted',
      browserData: navigator.userAgent || 'unknown',
    };

    fetch('https://api-v2.dailystream.com/logData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logData),
    })
      .then((response) => { })
      .catch((err) => {
        console.error('Error sending log data:', err);
      });
  } catch (err) {
    // Handle any errors within logError itself silently
    console.error('Error in logError function:', err);
  }
};

export default logger;
