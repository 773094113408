/**
 * Copyright Block
 */
import { sendData, onData } from '../Hooks/Socket';
import Users from './Users';
import logger from './Logger';
const FILE_NAME = 'Viewer.js';
class Viewer {
  constructor() {
    this.page = '';
    this.isPaused = false;
    this.isLoaded = false;
    this.skipBlock = [];
    this.isReady = false;
    this.canPlayNow = false;
    this.userId = '';
    this.forwardFramesLoaded = 0;
    this.minFrameBuffer = 3;
    this.liveOffset = 2;
    this.maxFrameBuffer = 10; //A buffer of 10 frames forward and back
    this.currentStreamPos = 0;
    this.clipStartPosition = 0; //Possibly Deprecated
    this.volume = 0.5;
    this.beforeMutedVolume = null;
    this.createFrameTimeout = 500;
    this.createFrameTimer = null;
    this.isMuted = false;
    this.active = false;
    this.streamStartTime = null;
    this.streamDuration = null;
    this.streamFirstClip = 0;
    this.lastStreamPosition = 0;
    this.MyDate = new Date();
    this.customDay =
      ('0' + (this.MyDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.MyDate.getDate()).slice(-2) +
      '-' +
      this.MyDate.getFullYear();
    this.TODAY = this.customDay;
    this.streamArray = [];
    this.clipId = 0;
    this.urlUser = '';
    this.userList = [];
    this.frameIndexTimer = null;
    this.currentStreamSegment = 0;
    this.clipStartTime = 0;
    this.isEnd = false;
    this.userInteraction = false;
    this.frameDurations = [];
    //Implemented: 10/18/2022
    //Legacy: Frame durations were not available in the past so we had to approximate them
    //This is no longer needed but we keep it here for backwards compatibility
    this.useFrameDurations = false;

    onData('getUserList', this.onGetUserList);
    onData('getClipData', this.onGetClipData);
    onData('getStreamData', this.onGetStreamData);
    onData('loadFrame', this.onLoadFrame);
    onData('addFilePart', this.onAddFilePart);
  }

  getUserList = () => {
    let userListData = {
      genUserId: localStorage.getItem('genUserId'),
    };
    sendData('getUserList', userListData);
  };

  /**
   * Sets the user list for mentions
   * @param {JSON} data response data
   * @param {Number} data.id the id of the user
   * @param {String} data.name the name of the user
   */
  onGetUserList = (data) => {
    this.userList = [];

    for (let i = 0; i < data.length; i++) {
      let element = {};
      element.id = data[i].id.toString();
      element.display = data[i].name;
      this.userList.push(element);
    }
  };

  setRoom = (room, customDay, genUserId, isClip) => {
    if (isClip) {
      this.userId = room;
      this.customDay = customDay;
    } else {
      this.userId = room;
      this.customDay = customDay;
      this.getStreamData(genUserId);
    }
  };

  // CLIPS START
  // =======================================

  getClipData = (clipStartTime) => {
    try {
      if (!this.isPaused) {
        document.getElementById('play').style.display = 'none';
        document.getElementById('pause').style.display = 'inline';
      } else {
        document.getElementById('play').style.display = 'inline';
        document.getElementById('pause').style.display = 'none';
      }
    } catch (err) {
      console.error(err);
    }
    this.isLoaded = true;

    let clipData = {
      genUserId: localStorage.getItem('genUserId'),
      room: this.clipId,
      clipStartTime: clipStartTime,
    };
    sendData('getClipData', clipData);
  };

  /**
   * Sets video element source to signed url
   * for clip playback
   * @param {JSON} data response data
   * @param {Array} data.urls the signed urls to create the clip playback with
   */
  onGetClipData = (data) => {
    this.active = true;
    this.isReady = true;

    try {
      document.getElementById('controlPanel').style.display = 'block';
      document.getElementById('playerSpinner').style.display = 'none';
      document.getElementById('playerContainer').style.display = 'flex';

      this.createClipPlayer(data.urls, data.clipStartTime);
    } catch (err) {
      console.error(err);
    }
  };

  createClipPlayer = (videos, clipStartTime) => {
    let vidElements = [];

    for (let i = 1; i <= videos.length; i++) {
      let vidElement = document.createElement('video');
      vidElement.setAttribute('id', `video${i}-${i}`); //HTML Elements are not zero index
      vidElement.setAttribute('class', `video${i} video${i}t`);
      vidElement.preload = 'metadata';
      vidElement.src = videos[i - 1];
      vidElement.currentTime = clipStartTime;

      if (i == 1) {
        vidElement.ontimeupdate = (e) => this.clipTimeUpdate(e.srcElement);
      }
      vidElement.onloadedmetadata = (e) => this.playClipElement(e.srcElement);
      vidElement.onended = (e) => this.clipEnded(e.srcElement);

      vidElements.push(vidElement);
    }

    for (let i = 1; i <= vidElements.length; i++) {
      document.getElementById(`videos${i}`).textContent = '';
      document.getElementById(`videos${i}`).replaceChildren(vidElements[i - 1]);
    }

    if (!this.isMuted) {
      this.setAllClipVolume(this.volume);
    } else {
      this.setAllClipVolume(0);
    }
  };

  playClipElement = (e) => {
    this.setScrubberDuration(
      parseInt(document.getElementById(`video1-1`).duration - 1)
    );

    if (!this.isPaused) {
      e.play();
    } else {
      e.pause();
    }
  };

  clipEnded = (e) => {
    document.getElementById('pause').style.display = 'none';
    document.getElementById('play').style.display = 'inline';
  };

  clipTimeUpdate = (e) => {
    try {
      if (!this.userInteraction) {
        let position = parseInt(e.currentTime);
        document.getElementById('position').value = position;

        this.updateClipTimeIndex(e.currentTime);
      }
    } catch (err) {
      //console.error(err);
    }
  };

  playClipFromPosition = (position) => {
    let video1 = document.getElementById('video1-1');
    let video2 = document.getElementById('video2-2');
    let video3 = document.getElementById('video3-3');

    if (video1) {
      video1.currentTime = position;
    }
    if (video2) {
      video2.currentTime = position;
    }
    if (video3) {
      video3.currentTime = position;
    }
  };

  restartClip = () => {
    let video1 = document.getElementById('video1-1');
    let video2 = document.getElementById('video2-2');
    let video3 = document.getElementById('video3-3');

    if (video1) {
      video1.currentTime = 0;
    }
    if (video2) {
      video2.currentTime = 0;
    }
    if (video3) {
      video3.currentTime = 0;
    }
  };

  pauseClip = () => {
    let video1 = document.getElementById('video1-1');
    let video2 = document.getElementById('video2-2');
    let video3 = document.getElementById('video3-3');

    this.isPaused = true;
    document.getElementById('pause').style.display = 'none';
    document.getElementById('play').style.display = 'inline';

    if (video1) {
      video1.pause();
    }
    if (video2) {
      video2.pause();
    }
    if (video3) {
      video3.pause();
    }
  };

  playClip = () => {
    let video1 = document.getElementById('video1-1');
    let video2 = document.getElementById('video2-2');
    let video3 = document.getElementById('video3-3');

    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'inline';
    this.isReady = true;
    this.isPaused = false;
    this.isLoaded = true;

    if (video1) {
      video1.play();
    }
    if (video2) {
      video2.play();
    }
    if (video3) {
      video3.play();
    }
  };

  endClip = () => {
    let video1 = document.getElementById('video1-1');
    let video2 = document.getElementById('video2-2');
    let video3 = document.getElementById('video3-3');

    if (video1) {
      video1.currentTime = video1.duration;
    }
    if (video2) {
      video2.currentTime = video2.duration;
    }
    if (video3) {
      video3.currentTime = video3.duration;
    }
  };

  jumpForwardClip = () => {
    if (!document.getElementById('jforwardButton').disabled) {
      document.getElementById('jforwardButton').disabled = true;
      //Optimize for multiple monitors
      let video1 = document.getElementById('video1-1');
      let video2 = document.getElementById('video2-2');
      let video3 = document.getElementById('video3-3');

      if (video1) {
        video1.currentTime += 10;
      }
      if (video2) {
        video2.currentTime += 10;
      }
      if (video3) {
        video3.currentTime += 10;
      }

      setTimeout(() => {
        document.getElementById('jforwardButton').disabled = false;
      }, 500);
    }
  };

  jumpBackClip = () => {
    if (!document.getElementById('jbackButton').disabled) {
      document.getElementById('jbackButton').disabled = true;
      //Optimize for multiple monitors
      let video1 = document.getElementById('video1-1');
      let video2 = document.getElementById('video2-2');
      let video3 = document.getElementById('video3-3');

      if (video1) {
        video1.currentTime -= 10;
      }
      if (video2) {
        video2.currentTime -= 10;
      }
      if (video3) {
        video3.currentTime -= 10;
      }

      setTimeout(() => {
        document.getElementById('jbackButton').disabled = false;
      }, 500);
    }
  };

  muteClip = () => {
    let mute = document.getElementById('mute');
    this.isMuted = !this.isMuted;
    if (this.isMuted) {
      this.beforeMutedVolume = this.volume;
      this.setAllClipVolume(0);

      mute.innerHTML = '🔇';
      document.getElementById('volume').value = 0;
    } else {
      this.setAllClipVolume(this.beforeMutedVolume);

      mute.innerHTML = '🔊';
      document.getElementById('volume').value = this.beforeMutedVolume * 100;
    }
  };

  setAllClipVolume = (volume) => {
    let video1 = document.getElementById(`video1-1`);
    let video2 = document.getElementById(`video2-2`);
    let video3 = document.getElementById(`video3-3`);

    if (video1) {
      video1.volume = volume;
    }
    if (video2) {
      video2.volume = 0;
    }
    if (video3) {
      video3.volume = 0;
    }
  };

  updateClipTimeIndex = (currentTime) => {
    try {
      let adjustedTimeMS = currentTime * 1000;
      let time = new Date(parseInt(this.clipStartTime)).getTime();
      let adjustedTimeIndex = new Date(time + adjustedTimeMS).toTimeString();
      document.getElementById('timeIndex').textContent = adjustedTimeIndex;
    } catch (err) {
      console.error(err);
    }
  };

  // CLIPS END
  // =======================================

  // STREAMS START
  // =======================================

  getStreamData = (genUserId) => {
    let streamData = {
      genUserId: genUserId,
      room: this.userId,
      date: this.customDay,
    };
    sendData('getStreamData', streamData);
  };

  /**
   * Sets playback data for viewer
   * @param {JSON} data response data
   */
  onGetStreamData = (data) => {
    if (data[0] && data[0].length >= 1) {
      this.streamArray = data[0];
      let lastPost = data[0].length - 1;
      let tempDuration = 0;
      this.streamDurations = [];

      //Implemented 10/18/2022
      //Legacy: Frame durations were not avaiable on old streams
      //Use old method of calculating duration
      if (data[0][0]?.frameDurations) {
        this.useFrameDurations = true;
      } else {
        this.useFrameDurations = false;
      }

      for (let i = 0; i < data[0].length; i++) {
        //Implemented 10/18/2022
        //Legacy: Frame durations were not avaiable on old streams
        //Use old method of calculating duration
        if (this.useFrameDurations) {
          let segmentFrameDurations = data[0][i].frameDurations;
          segmentFrameDurations = segmentFrameDurations.split(',');
          this.frameDurations.push(...segmentFrameDurations);
        }

        tempDuration += data[0][i].duration;
      }

      this.streamDuration = tempDuration;
      this.currentStreamPos = data[0][0].firstPosition;
      this.streamFirstClip = data[0][0].firstPosition;
      this.lastStreamPosition = data[0][lastPost].lastPosition;
      this.setScrubberDuration(this.lastStreamPosition);
      this.streamStartTime = data[0][0].startTime;
      this.active = true;
      this.isReady = true;

      try {
        document.getElementById('controlPanel').style.display = 'block';
        document.getElementById('playerSpinner').style.display = 'none';
        document.getElementById('playerContainer').style.display = 'flex';
      } catch (err) {
        console.error(err);
      }
    }
  };

  /**
   * Updates stream data after file metadata
   * has been saved
   * @param {JSON} data response data
   * @param {Number} data.userId the id of the user
   * @param {Number} data.state the state of the user
   * @param {Number} data.streamPosition the last stream position of the user
   */
  onAddFilePart = (data) => {
    if (this.userId == data.userId && this.TODAY == this.customDay) {
      this.lastStreamPosition = data.streamPosition;
    }

    // let userIndex = Users.usersArray.findIndex(user => user.id == data.userId);
    // Users.usersArray[userIndex].lastActive = new Date().getTime();
    // Users.usersArray[userIndex].live = true;

    // Users.updateUserLive(data.userId);
  };

  endVideo = () => {
    let userIndex = Users.usersList.findIndex((user) => user.id == this.userId);

    if (this.customDay != this.TODAY) {
      return true;
    } else if (userIndex >= 0) {
      if (Date.now() - Users.usersList[userIndex].lastActive > 10000) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  liveFrame = () => {
    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'inline';

    let livePos = null;
    if (this.lastStreamPosition <= this.liveOffset) {
      livePos = this.lastStreamPosition;
    } else {
      livePos = this.lastStreamPosition - this.liveOffset;
    }

    this.start(livePos, 0);
  };

  restartFrame = () => {
    this.start(0, 0);
  };

  jumpBackFrame = () => {
    if (!document.getElementById('jbackButton').disabled) {
      document.getElementById('jbackButton').disabled = true;
      if (this.isEnd) {
        this.isEnd = false;
        this.start(this.getCurrentPos() - 1, 0);
      } else {
        let video1 = document.getElementById(`video${this.getCurrentPos()}-1`);
        let video2 = document.getElementById(`video${this.getCurrentPos()}-2`);
        let video3 = document.getElementById(`video${this.getCurrentPos()}-3`);

        let timeLeft = video1.currentTime - 10.0;

        if (timeLeft < 0.0) {
          let curPos = this.getCurrentPos();
          let prevPos = this.getCurrentPos() - 1;

          if (prevPos <= 0) {
            prevPos = 0;
            timeLeft = 0.0;
          }

          this.switchFrame(curPos, prevPos, timeLeft);
          this.deleteFrame(curPos + this.maxFrameBuffer);
        } else {
          if (video1) {
            video1.currentTime -= 10.0;
          }

          if (video2) {
            video2.currentTime -= 10.0;
          }

          if (video3) {
            video3.currentTime -= 10.0;
          }
        }
      }

      if (
        !document.getElementById(`video${this.getCurrentPos() - 1}-1`) &&
        this.getCurrentPos() >= 1
      ) {
        this.loadFrame(this.getCurrentPos() - 1, false, true, 0);
      }

      setTimeout(() => {
        document.getElementById('jbackButton').disabled = false;
      }, 500);
    }
  };

  jumpForwardFrame = () => {
    if (!document.getElementById('jforwardButton').disabled) {
      document.getElementById('jforwardButton').disabled = true;

      //Optimize for multiple monitors
      let video1 = document.getElementById(`video${this.getCurrentPos()}-1`);
      let video2 = document.getElementById(`video${this.getCurrentPos()}-2`);
      let video3 = document.getElementById(`video${this.getCurrentPos()}-3`);

      let timeLeft = video1.duration - video1.currentTime;

      if (timeLeft < 10.0) {
        let curPos = this.getCurrentPos();
        let nextPos = this.getCurrentPos() + 1;
        let time = -1 * (timeLeft - 10); //Time to jump to in frame

        if (nextPos > this.lastStreamPosition) {
          this.createEndFrame();
        }

        this.switchFrame(curPos, nextPos, time);
        this.deleteFrame(curPos - this.maxFrameBuffer);
        this.forwardFramesLoaded--;
      } else {
        if (video1) {
          video1.currentTime += 10.0;
        }

        if (video2) {
          video2.currentTime += 10.0;
        }

        if (video3) {
          video3.currentTime += 10.0;
        }
      }

      setTimeout(() => {
        document.getElementById('jforwardButton').disabled = false;
      }, 500);
    }
  };

  setFrameTime = (position, time) => {
    //Optimize for multiple monitors
    let video1 = document.getElementById(`video${position}-1`);
    let video2 = document.getElementById(`video${position}-2`);
    let video3 = document.getElementById(`video${position}-3`);

    if (time < 0.0) {
      if (video1) {
        video1.currentTime = video1.duration + time;
      }

      if (video2) {
        video2.currentTime = video2.duration + time;
      }

      if (video3) {
        video3.currentTime = video3.duration + time;
      }
    } else {
      if (video1) {
        video1.currentTime = time;
      }

      if (video2) {
        video2.currentTime = time;
      }

      if (video3) {
        video3.currentTime = time;
      }
    }
  };

  switchFrame = (oldFrame, newFrame, time) => {
    //Optitmize for multiple monitors
    let oldVideo1 = document.getElementById(`video${oldFrame}-1`);
    let oldVideo2 = document.getElementById(`video${oldFrame}-2`);
    let oldVideo3 = document.getElementById(`video${oldFrame}-3`);

    this.setCurrentPos(newFrame);

    if (oldVideo1) {
      // oldVideo1.style.display = 'none';
      oldVideo1.style.zIndex = -999;
      oldVideo1.pause();
      oldVideo1.currentTime = 0;

      if (oldVideo2) {
        // oldVideo2.style.display = 'none';
        oldVideo2.style.zIndex = -999;
        oldVideo2.pause();
        oldVideo2.currentTime = 0;
      }
      if (oldVideo3) {
        // oldVideo2.style.display = 'none';
        oldVideo3.style.zIndex = -999;
        oldVideo3.pause();
        oldVideo3.currentTime = 0;
      }

      this.playFrame(newFrame, time);
    }
  };

  resumeFrame = () => {
    this.isPaused = false;

    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'inline';

    //Optimize for multiple monitors
    let frame1 = document.getElementById(`video${this.getCurrentPos()}-1`);
    let frame2 = document.getElementById(`video${this.getCurrentPos()}-2`);
    let frame3 = document.getElementById(`video${this.getCurrentPos()}-3`);

    if (frame1) {
      frame1.play();
    }
    if (frame2) {
      frame2.play();
    }
    if (frame3) {
      frame3.play();
    }
  };

  pauseFrame = () => {
    this.isPaused = true;
    document.getElementById('pause').style.display = 'none';
    document.getElementById('play').style.display = 'inline';

    //Optimize for multiple monitors
    let frame1 = document.getElementById(`video${this.getCurrentPos()}-1`);
    let frame2 = document.getElementById(`video${this.getCurrentPos()}-2`);
    let frame3 = document.getElementById(`video${this.getCurrentPos()}-3`);

    if (frame1) {
      frame1.pause();
    }
    if (frame2) {
      frame2.pause();
    }
    if (frame3) {
      frame3.pause();
    }
  };

  scrubberUpdateFrame = (position) => {
    this.currentStreamPos = parseInt(position);

    let time = this.calculateTimeInFrame(position);

    document.getElementById('position').value = position;

    this.start(this.currentStreamPos, time);
  };

  stopClip = (clip_name, description, tags) => {
    sendData('bookmark', {
      userId: this.userId,
      clip_name: clip_name,
      description: description,
      start_clip: this.clipStartPosition,
      end_clip: this.getCurrentPos(),
      is_clip: true,
      category: 1,
      tags: tags,
      date: this.customDay,
    });
    this.clipStartPosition = 0;
  };

  bookMark = (clip_name) => {
    clip_name = `${this.userId}-${clip_name}`;
    sendData('bookmark', {
      userId: this.userId,
      clip_name: clip_name,
      description: null,
      start_clip: this.getCurrentPos(),
      end_clip: this.getCurrentPos(),
      is_clip: false,
      category: 1,
      dateTime: this.customDay,
    });
  };

  start = (position, time) => {
    // logger('log', FILE_NAME, 710, `Start: ${position} - Time: ${time}`, false, true);

    clearTimeout(this.frameIndexTimer);

    if (!this.isPaused) {
      document.getElementById('play').style.display = 'none';
      document.getElementById('pause').style.display = 'inline';
    } else {
      document.getElementById('play').style.display = 'inline';
      document.getElementById('pause').style.display = 'none';
    }

    this.isLoaded = true;
    this.isReady = true;

    if (this.isReady) {
      this.skipBlock = [];
      this.setCurrentPos(position);
      this.canPlayNow = false;
      this.forwardFramesLoaded = 0;
      clearTimeout(this.createFrameTimer);

      if (document.getElementById(`videos1`).innerHTML != null) {
        document.getElementById(`videos1`).innerHTML = '';
        document.getElementById(`videos2`).innerHTML = '';
        document.getElementById(`videos3`).innerHTML = '';
      }

      if (position != 0) {
        this.loadFrame(position - 1, false, true, 0);
      }

      this.loadFrame(position, true, false, time);
    } else {
      this.createEndFrame();
    }
  };

  checkStreamSegment = (position) => {
    if (
      this.streamArray[this.currentStreamSegment] == undefined ||
      this.streamArray[this.currentStreamSegment].firstPosition >= position ||
      this.streamArray[this.currentStreamSegment].lastPosition <= position
    ) {
      this.getStreamSegment(position);
    }
  };

  getStreamSegment = (position) => {
    for (let i = 0; i < this.streamArray.length; i++) {
      if (
        this.streamArray[i].firstPosition <= position &&
        this.streamArray[i].lastPosition >= position
      ) {
        this.currentStreamSegment = i;
      }
    }
  };

  updateTime = (frame) => {
    try {
      //Leave commented out possibly a new feature
      //this.positionIndex = this.msToTime(this.currentStreamPos * 30000);
      //this.durationIndex = this.msToTime(this.lastStreamPosition * 30000);
      //document.getElementById('current').textContent = this.positionIndex;
      //document.getElementById('files').textContent = this.durationIndex;
      clearTimeout(this.frameIndexTimer);
      frame = parseInt(frame);
      this.checkStreamSegment(frame);
      let currentStreamDateTime = null;
      let timeInFrame = null;

      //Implemented: 10/18/2022
      //Legacy: This is for the old way of calculating time
      //Frame durations were not avaialable on past streams
      //Time was estimated based on the frame number
      if (this.useFrameDurations) {
        currentStreamDateTime = new Date(
          parseInt(this.streamArray[this.currentStreamSegment].startTime) +
            (this.frameDurations[frame] -
              this.frameDurations[
                this.streamArray[this.currentStreamSegment].firstPosition
              ])
        );

        let duration = parseInt(
          this.frameDurations[frame + 1] - this.frameDurations[frame]
        );

        if (frame > 1) {
          timeInFrame = (frame % parseInt(frame)) * duration;
        } else {
          timeInFrame = frame * duration;
        }
      } else {
        currentStreamDateTime = new Date(
          parseInt(this.streamArray[this.currentStreamSegment].startTime) +
            (frame -
              this.streamArray[this.currentStreamSegment].firstPosition) *
              30000
        );

        let duration =
          this.streamArray[this.currentStreamSegment].lastPosition != frame
            ? 30000
            : this.streamArray[this.currentStreamSegment].duration -
              (this.streamArray[this.currentStreamSegment].lastPosition -
                this.streamArray[this.currentStreamSegment].firstPosition) *
                30000;

        if (frame > 1) {
          timeInFrame = (frame % parseInt(frame)) * duration;
        } else {
          timeInFrame = frame * duration;
        }
      }

      currentStreamDateTime = new Date(
        currentStreamDateTime.getTime() + timeInFrame
      );
      document.getElementById('timeIndex').textContent =
        currentStreamDateTime.toTimeString();
      this.startFrameIndexTimer(currentStreamDateTime);
    } catch (err) {
      console.error(err);
    }
  };

  startFrameIndexTimer = (date) => {
    this.frameIndexTimer = setTimeout(() => {
      try {
        if (!this.userInteraction) {
          let timeElapsed =
            document.getElementById(`video${this.getCurrentPos()}-1`)
              .currentTime * 1000;
          let adjustedTime = timeElapsed + date.getTime();

          let newDate = new Date(adjustedTime);
          document.getElementById('timeIndex').textContent =
            newDate.toTimeString();
          clearTimeout(this.frameIndexTimer);
          this.startFrameIndexTimer(date);
        }
      } catch (err) {
        console.error(err);
        //this.startFrameIndexTimer(date)
      }
    }, 100);
  };

  loadFrame = (frame, start, previousFrame, time) => {
    let frameData = {
      file: frame,
      day: this.customDay,
      room: this.userId,
      start: start,
      genUserId: localStorage.getItem('genUserId'),
      time: time,
      previousFrame: previousFrame,
    };
    sendData('loadFrame', frameData);
  };

  /**
   * Handles the response to
   * loading the frame data
   * for playback
   * @param {JSON} data response data
   * @param {Number} data.skip if the file is to be skipped
   * @param {Number} data.file the frame (file) that was loaded
   * @param {Array} data.signedUrls the signed urls for the frames that were loaded
   * @param {Number} data.time the time to start the frame at
   * @param {Boolean} data.start if the frame is the starting frame
   */
  onLoadFrame = (data) => {
    if (data.skip) {
      if (data.start) {
        this.setCurrentPos(this.getCurrentPos() + 1);
      }
      this.skipBlock.push(data.frame);
      this.createNextFrame(++data.frame, data.start, 0);
    } else if (data.signedUrls && data.signedUrls.length > 0) {
      this.createFrame(
        data.signedUrls,
        data.frame,
        data.start,
        data.time,
        data.previousFrame
      );
    }
  };

  createFrame = (urls, framePos, start, time, previousFrame) => {
    if (!document.getElementById(`video${framePos}-1`)) {
      for (let i = 1; i <= urls.length; i++) {
        let frame = document.createElement('video');
        frame.setAttribute('id', `video${framePos}-${i}`);
        frame.setAttribute('class', `video${i} video${i}t`);
        frame.src = urls[i - 1];
        frame.preload = 'metadata';
        // frame.style.display = 'none'
        frame.style.zIndex = -999;

        if (i === 1) {
          frame.ontimeupdate = (e) => this.frameUpdate(e.srcElement);
          frame.onended = (e) => this.frameEnded(e.srcElement);
          frame.onerror = (e) => this.frameError(e.srcElement);
        }

        try {
          document.getElementById(`videos${i}`).append(frame);
        } catch (err) {
          console.error(err);
        }
      }
      this.forwardFramesLoaded++;

      if (start) {
        this.playFrame(framePos, time);
      }

      if (!previousFrame) {
        this.createNextFrame(++framePos, false, 0);
      }
    }
  };

  createEndFrame = () => {
    document.getElementById('videos1').innerHTML = '';
    document.getElementById('videos2').innerHTML = '';
    document.getElementById('videos3').innerHTML = '';
    clearTimeout(this.frameIndexTimer);
    this.isEnd = true;
    this.forwardFramesLoaded = 0;

    let endFrame = document.createElement('video');
    endFrame.setAttribute('id', `video${this.lastStreamPosition + 1}-1`);
    endFrame.setAttribute('class', 'video1 video1t');
    endFrame.src = 'https://cdn.dailystream.com/videos/endstream.mp4';
    endFrame.preload = 'metadata';
    endFrame.loop = true;
    endFrame.autoplay = true;
    // endFrame.style.display = 'block';
    endFrame.style.zIndex = 1;

    document.getElementById('videos1').append(endFrame);

    this.playEndFrame();
  };

  playEndFrame = () => {
    let video1 = document.getElementById(
      `video${this.lastStreamPosition + 1}-1`
    );
    let video2 = document.getElementById(
      `video${this.lastStreamPosition + 1}-2`
    );
    let video3 = document.getElementById(
      `video${this.lastStreamPosition + 1}-3`
    );

    if (video1) {
      // video1.style.display = 'none';
      video1.zIndex = -999;
    }
    if (video2) {
      // video2.style.display = 'none';
      video2.zIndex = -999;
    }
    if (video3) {
      // video3.style.display = 'none';
      video3.zIndex = -999;
    }
  };

  deleteFrame = (frame) => {
    let frame1 = document.getElementById(`video${frame}-1`);
    let frame2 = document.getElementById(`video${frame}-2`);
    let frame3 = document.getElementById(`video${frame}-3`);

    if (frame1) {
      frame1.parentNode.removeChild(frame1);
    }
    if (frame2) {
      frame2.parentNode.removeChild(frame2);
    }
    if (frame3) {
      frame3.parentNode.removeChild(frame3);
    }
  };

  playFrame = (frame, time) => {
    //Optimize for multiple monitors
    if (this.skipBlock.includes(frame)) {
      if (this.getCurrentPos() + 1 > this.lastStreamPosition) {
        this.createEndFrame();
      } else {
        this.setCurrentPos(this.getCurrentPos() + 1);
        this.playFrame(++frame, 0);
      }
    } else {
      let frame1 = document.getElementById(`video${frame}-1`);
      let frame2 = document.getElementById(`video${frame}-2`);
      let frame3 = document.getElementById(`video${frame}-3`);

      this.deleteFrame(frame - this.maxFrameBuffer);

      if (!this.isMuted) {
        this.setAllFrameVolume(this.volume);
      } else {
        this.setAllFrameVolume(0);
      }

      if (time < 0.0) {
        if (frame1) {
          frame1.currentTime = frame1.duration + time;
          // frame1.style.display = 'block';
          frame1.style.zIndex = 1;

          if (!this.isPaused) {
            frame1.play();
          } else {
            frame1.pause();
          }
        }
        if (frame2) {
          frame2.currentTime = frame2.duration + time;
          // frame2.style.display = 'block';
          frame2.style.zIndex = 1;

          if (!this.isPaused) {
            frame2.play();
          } else {
            frame2.pause();
          }
        }
        if (frame3) {
          frame3.currentTime = frame3.duration + time;
          // frame3.style.display = 'block';
          frame3.style.zIndex = 1;

          if (!this.isPaused) {
            frame3.play();
          } else {
            frame3.pause();
          }
        }
      } else {
        if (frame1) {
          frame1.currentTime = time;
          // frame1.style.display = 'block';
          frame1.style.zIndex = 1;

          if (!this.isPaused) {
            frame1.play();
          } else {
            frame1.pause();
          }
        }
        if (frame2) {
          frame2.currentTime = time;
          // frame2.style.display = 'block';
          frame2.style.zIndex = 1;

          if (!this.isPaused) {
            frame2.play();
          } else {
            frame2.pause();
          }
        }
        if (frame3) {
          frame3.currentTime = time;
          // frame3.style.display = 'block';
          frame3.style.zIndex = 1;

          if (!this.isPaused) {
            frame3.play();
          } else {
            frame3.pause();
          }
        }
      }
      this.updateTime(this.getCurrentPos());
    }
  };

  frameUpdate = (e) => {
    try {
      if (!this.userInteraction) {
        let position = e.currentTime / 30.0 + this.getCurrentPos();

        document.getElementById('position').value = position;
      }
    } catch (err) {
      //console.error(err);
    }
  };

  frameEnded = () => {
    //Optimize for multiple monitors
    let frame1 = document.getElementById(`video${this.getCurrentPos()}-1`);
    let frame2 = document.getElementById(`video${this.getCurrentPos()}-2`);
    let frame3 = document.getElementById(`video${this.getCurrentPos()}-3`);

    if (frame1) {
      // frame1.style.display = 'none';
      frame1.style.zIndex = -999;
    }
    if (frame2) {
      // frame2.style.display = 'none';
      frame2.style.zIndex = -999;
    }
    if (frame3) {
      // frame3.style.display = 'none';
      frame3.style.zIndex = -999;
    }

    this.forwardFramesLoaded--;

    if (this.getCurrentPos() + 1 > this.lastStreamPosition) {
      this.createEndFrame();
    } else {
      this.setCurrentPos(this.getCurrentPos() + 1);
      this.playFrame(this.getCurrentPos(), 0);
    }
  };

  setAllFrameVolume = (volume) => {
    //This prevents the previous audio tracks
    //from overlapping with the next frame's audio tracks
    try {
      let prevFrame = document.getElementById(
        `video${this.getCurrentPos() - 1}-1`
      );
      prevFrame.volume = 0;
    } catch (e) {
      //console.error(e)
    }

    let frame1 = document.getElementById(`video${this.getCurrentPos()}-1`);
    let frame2 = document.getElementById(`video${this.getCurrentPos()}-2`);
    let frame3 = document.getElementById(`video${this.getCurrentPos()}-3`);

    if (frame1) {
      frame1.volume = volume;
    }
    if (frame2) {
      frame2.volume = 0;
    }
    if (frame3) {
      frame3.volume = 0;
    }
  };

  createNextFrame = (frame, start, time) => {
    clearTimeout(this.createFrameTimer);
    this.createFrameTimer = setTimeout(() => {
      if (
        this.forwardFramesLoaded < this.minFrameBuffer &&
        !document.getElementById(`video${frame}-1`) &&
        frame <= this.lastStreamPosition
      ) {
        this.loadFrame(frame, start, false, time);
      } else {
        this.createNextFrame(frame, start, time);
      }
    }, this.createFrameTimeout);
  };

  mute = () => {
    let mute = document.getElementById('mute');
    this.isMuted = !this.isMuted;

    if (this.isMuted) {
      this.beforeMutedVolume = this.volume;
      this.setAllFrameVolume(0);

      mute.innerHTML = '🔇';
      document.getElementById('volume').value = 0;
    } else {
      this.setAllFrameVolume(this.beforeMutedVolume);

      mute.innerHTML = '🔊';
      document.getElementById('volume').value = this.beforeMutedVolume * 100;
    }
  };

  // STREAMS END
  // =======================================

  // UTILITY STRART
  // =======================================

  canplay = () => {
    this.canPlayNow = true;
  };

  getCurrentPos = () => {
    return this.currentStreamPos < 0 ? 0 : this.currentStreamPos;
  };

  setCurrentPos = (video) => {
    this.currentStreamPos = video < 0 ? 0 : video;
  };

  setCustomDay = (date) => {
    this.customDay = date;
  };

  getCustomDay = () => {
    return this.customDay;
  };

  unsetRoom = () => {
    this.userId = null;
  };

  getRoom = () => {
    return this.userId;
  };

  //Update to use new frame durations array
  calculateTimeInFrame = (frame) => {
    let segmentLength =
      this.streamArray[this.currentStreamSegment].lastPosition != frame
        ? 30
        : this.streamArray[this.currentStreamSegment].duration -
          ((this.streamArray[this.currentStreamSegment].lastPosition -
            this.streamArray[this.currentStreamSegment].firstPosition) *
            30000) /
            1000;

    let time = null;
    if (frame > 1) {
      time = (frame % parseInt(frame)) * segmentLength;
    } else {
      time = frame * segmentLength;
    }
    return time;
  };

  _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  msToTime = (duration) => {
    let milliseconds = parseInt((duration % 1000) / 100);
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  };
}

export default new Viewer();
